<template>
  <div id="app">
    <!--<nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>-->
    <Header :mobile="isMobile"/>
    <router-view></router-view>
  </div>
</template>
<script>
  import Header from "@/components/pc/Header";
  import {ISMOBILE} from './utils/index'
  export default {
    data(){
      return{
        isMobile:false,
      }
    },
    components: {Header},
    mounted() {
      if(ISMOBILE()){
        this.isMobile = true;
      }

    }
  }
</script>
<style lang="less">
  @import "styles/imports";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
  .el-main{
    padding:0 !important;
    width: 100%;
    height: calc(100% - 88px);
    overflow: hidden !important;
  }
.head{
  height: @banner-height !important;
  padding:0 !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

