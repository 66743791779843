import axios from "axios";
import * as XLSX from "xlsx";
import transformSheets from "@/utils/read_xlsx";
// axios.defaults.baseURL = 'api';
export function readExcelFile(callBack) {
    let list = [];
    let url = "/bubi.xlsx" //放在public目录下的文件可以直接访问
    //读取二进制excel文件,参考https://github.com/SheetJS/js-xlsx#utility-functions
    axios.get(url, {responseType: 'arraybuffer'})
        .then((res) => {
            let data = new Uint8Array(res.data)
            let wb = XLSX.read(data, {type: "array"})
            let sheets = wb.Sheets
             list = transformSheets(sheets)[0];
            console.log(list);
            callBack(list);

        }).catch(err => {
        console.log(err)
        callBack(list);
    })
}

