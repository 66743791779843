<template>
    <div class="home">
        <Banner/>
        <Members :data="swiperList"/>
        <Pass/>
        <Partner/>
        <Footer/>
    </div>
</template>

<script>
    import Banner from "../../components/pc/home/Banner";
    import Members from "../../components/pc/home/Members";
    import Pass from "../../components/pc/home/Pass";
    import Partner from "../../components/pc/home/Partner";
    import {readExcelFile} from "../../utils/dataMap";
    import Footer from "../../components/pc/Footer";

    export default {
        name: 'HomeView',
        data() {
            return {
                swiperList: []
            }
        },
        components: {
            Footer,
            Partner,
            Pass,
            Members,
            Banner
        },
        mounted() {
            let _this = this;
            readExcelFile((res)=>{
                _this.getSwiperList(res);
            });
        },

        methods: {
            getSwiperList(list) {
                let _this = this;
                list.forEach(function (item) {
                    if (item['命名编号'].includes('UR') || item['命名编号'].includes('SSR')) {
                        _this.swiperList.push(item);
                    }
                })
            }
        }

    }
</script>
<style scoped lang="less">
    @import "../../styles/imports";

    .home {
        width: 100%;
    }
</style>
