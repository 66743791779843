<template>
    <div class="partner">
        <img class="mem_img1" src="../../../assets/PARTNERSHIPS.png">
        <div class="mem_main">
            <div class="title">- 合作伙伴 -</div>
            <div class="partner_cont">
                <img src="../../../assets/partner_01.png">
                <img src="../../../assets/partner_02.png">
                <img src="../../../assets/partner_03.png">
                <img src="../../../assets/partner_04.png">
                <img src="../../../assets/partner_05.png">
            </div>
            <div class="partner_cont partner_cont2">
                <div class="img_cont"><img src="../../../assets/cat.png"/></div>
                <div class="img_cont"><img src="../../../assets/juzhou.png"/></div>
                <div class="img_cont"/>
                <div class="img_cont"/>
                <div class="img_cont"/>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Partner"
    }
</script>

<style scoped lang="less">
    @import "../../../styles/imports";

    .partner {
        width: 100%;
        padding: 90px 0 40px;
        background-color: @mainColor;
        position: relative;

        .mem_img1 {
            position: absolute;
            left: calc((100% - 800px) / 2);
            width: 800px;
            height: auto;
            margin: 0 auto;
        }

        .mem_main {
            margin-top: 25px;
            width: 100%;

            .title {
                font-size: 60px;
                font-weight: 800;
                color: #fff;
                font-family: SH-Heavy;
            }

            .partner_cont {
                max-width: @max-width;
                margin: 220px auto 80px;
                display: flex;
                justify-content: space-between;
            }
            .partner_cont2{
                margin-top:0px !important;
                margin-bottom: 220px !important;
                .img_cont{
                   width: 238px;
                    text-align: center;
                }
            }

            .footer {
                width: 100%;
                text-align: center;
            }

            .footer_log {
                width: 138px;
                margin: 20px auto;
            }
        }
    }
</style>
