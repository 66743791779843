<template>
    <div class="members">
        <img class="mem_img1" src="../../../assets/Community_Member.png">
        <div class="mem_main">
            <div class="title">- 社区成员 -</div>
            <!--<div class="swiper-container" id="swiperIndex">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in data">
                        <img :src="item['图片链接']"/>
                        <div class="mask"></div>
                        <div>{{item['命名编号']}}</div>
                    </div>
                </div>
            </div>-->
            <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item,index) in data" :key="index">
                    <img :src="item['图片链接']"/>
                    <div class="mask"></div>
<!--                    <div>{{item['命名编号']}}</div>-->
                </swiper-slide>
            </swiper>
            <div class="btn" @click="goSeach">进入次元</div>
        </div>
    </div>
</template>

<script>
    // import Swiper from 'swiper';
    import {swiper, swiperSlide} from 'vue-awesome-swiper'
    import router from "../../../router";

    export default {
        name: "Members",
        props: ['data'],
        data() {
            return {
                swiperOption: {
                    // loop: true,
                    slidesPerView: 6,
                    // loopedSlides:5,
                    observe: true,
                    observeParents: true,
                    // spaceBetween: 20,
                    centeredSlides: true,
                    centeredSlidesBounds: true,
                    initialSlide:3,
                    slideToClickedSlide:true,
                    autoplay: {
                        disableOnInteraction: false,
                    },

                }
            }
        },
        components: {swiper, swiperSlide},
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            }
        },
        mounted() {
            this.$nextTick(() => {
                /*  console.log('999999999');
                  console.log(this.data);
                 new Swiper('#swiperIndex', {
                      loop: true,
                      autoplay: true,
                      slidesPerView: 6,
                     /!* observe:true,
                     observeParents:true,*!/
                  })*/
            })

        },
        methods:{
            goSeach(){
                router.push('/search');
            }
        }
    }
</script>

<style scoped lang="less">

    @import "../../../styles/imports";

    .members {
        width: 100%;
        padding: 90px 0;
        background-color: @mainColor;
        position: relative;

        .mem_img1 {
            position: absolute;
            left: calc((100% - 1000px) / 2);
            width: 1000px;
            height: auto;
            margin: 0 auto;
        }

        .mem_main {
            margin-top: 15px;
            width: 100%;

            .title {
                font-size: 60px;
                font-weight: 800;
                color: #fff;
                font-family: SH-Heavy;
            }

            .btn {
                font-size: 24px;
                color: #000;
                font-weight: 800;
                font-family: SH-Heavy;
                width: 280px;
                height: 66px;
                line-height: 66px;
                text-align: center;
                border-radius: 16px;
                background-color: #FF9953;
                margin: 0 auto;
            }

            .swiper-container {
                margin: 90px 0 138px;
                overflow: visible !important;
                width: 100%;

            }

            .swiper-slide {
                /*height: 95%;*/
                text-align: center;
                background: #fff;
                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                transition: 300ms;
                transform: scale(0.9);
                background: transparent;
                /*border-radius: 12px;*/
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 8px;
                }
            }
            .swiper-slide-active,
            .swiper-slide-duplicate-active {
                transform: scale(1.1);
                margin:0 20px;
            }
        }
    }
</style>
