<template>
    <div class="main">
        <div class="footer">© 2022 by longge2046</div>
        <img class="footer_log" src="../../assets/footer_log.png"/>
        <div class="footer_bz" style="padding-bottom:20px"><a href="https://beian.miit.gov.cn" target="_blank">合肥隆格网络科技有限公司
            网站备案号：皖ICP备2022012374号</a></div>
        <!--<div class="footer_bz" style="padding-bottom: 20px;">
            <a class="footer_a" target="_blank"
               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005951">
                <img src="../../assets/ghs.png">
                <span class="footer-text txt">
                皖公网安备：31011002005951号</span>
            </a>
        </div>-->
    </div>

</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="less">
    @import "../../styles/imports";

    .main {
        width: 100%;
        height: 100%;
        background: @mainColor;

        .footer {
            width: 100%;
            text-align: center;
            padding-top: 20px;
            color: #fff;
        }
        a {
            text-decoration: none;
            color: #fff;
        }
        .footer_a img {
            margin-top: -4px;
            display: inline-block;
            vertical-align: top;
        }
        .footer_log {
            width: 138px;
            margin: 20px auto;
        }

        .footer_bz {
            padding-bottom: 10px;
            width: 100%;
            text-align: center;
            color: #fff;
        }
    }
</style>
